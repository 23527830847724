import React, { useState, useEffect, useRef, createRef } from 'react';
import webSocket from 'socket.io-client';
import ReactHowler from 'react-howler';
import Timer from 'react-compound-timer';
import { Row, Col, Tooltip } from 'antd';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeXmark } from '@fortawesome/free-solid-svg-icons';
import CompanyPropService from '../services/companyprop.service';
import commonVariables from '../config/commonSetting';
import Hidden from '@material-ui/core/Hidden';
import { VolumeMute } from '@styled-icons/boxicons-regular/VolumeMute';
import icon1 from '../assets/images/alarm/alarm_oof.png';
import icon2 from '../assets/images/alarm/alarm_onn.gif';

const countdown = 30 * 60 * 1000;
const tiRef = createRef(); //add this to reset timer

const languageMap = commonVariables.languageMap;

function useInterval(callback, delay) {
  let savedCallback = useRef();

  // 儲存新回撥
  useEffect(() => {
    savedCallback.current = callback;
  });

  // 建立 interval
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function WaterLeakageAlarm(props) {
  const selectedLang = localStorage.getItem('i18nextLng') || 'en';
  let { t } = useTranslation();

  let [menuAnchor, setMenuAnchor] = useState(null);
  useEffect(() => {
    document.body.dir = languageMap[selectedLang].dir;
  }, [menuAnchor, selectedLang]);

  let wshostname = commonVariables.wshostname; //+"/AVANIoT/Alarm";
  let currentCompProp = CompanyPropService;
  let [current_Building, setCurrent_Building] = useState({
    company: currentCompProp.getCurrentCompany().company,
    property: currentCompProp.getCurrentProperty().property
  });
  let [pause, setPause] = useState(false);
  const ws = React.useRef(null);
  let [normal, setNormal] = useState(true);
  let [normal2, setNormal2] = useState(true);
  let [trigger, setTrigger] = useState(false);
  let [alarmIcon, setIcon] = useState(icon1);

  useEffect(() => {
    ws.current = webSocket(wshostname);

    if (ws.current) {
      //連線成功在 console 中打印訊息
      console.log('WebSocket connected!');
      //設定監聽
      initWebSocket();
    }
  }, []);

  let initWebSocket = () => {
    //對 getMessage 設定監聽，如果 server 有透過 getMessage 傳送訊息，將會在此被捕捉
    ws.current.on('lite_water_leakage_alarm', (payload) => {
      let normal = true;
      payload = payload.filter(
        (property) =>
          property._id.property == current_Building.property &&
          property._id.company == current_Building.company
      );
      // console.log(payload);
      let property = payload[0];
      if (property) {
        // for (let property of payload) {
        // if (property._id == current_Building.property) {
        if (property.count > 0) {
          normal = false;
        } else {
          normal = true;
        }
        // break;
        // }
      }
      setNormal(normal);
    });
    /*ws.current.on('lite_fall_detection_alarm', (payload) => {
      let normal = true;
      payload = payload.filter(
        (property) => property._id.property == current_Building.property && property._id.company == current_Building.company
      );
      // console.log(payload);
      let property = payload[0];
      if (property)
      // for (let property of payload) {
        {
        // if (property._id == current_Building.property) {
          if (property.count > 0) {
            normal = false;
          } else {
            normal = true;
          }
          // break;
        // }
      }
      setNormal2(normal);
    });*/
  };

  let checkAlarm = () => {
    if (!normal || !normal2) {
      if (pause == true) {
        setTrigger(false);
        setIcon(icon2);
      } else {
        setTrigger(true);
        setIcon(icon2);
      }
    } else {
      setTrigger(false);
      setIcon(icon1);
    }
    if (pause == true) {
      setTrigger(false);
    }
  };

  useInterval(() => {
    checkAlarm();
  }, 1000);

  return (
    <>
      <div>
        <Row gutter={16} justify="center" align="middle">
          <Col span={4}>
            <ReactHowler src={['alarm.mp3']} playing={trigger} loop={true} />
            <Tooltip title={t('water_leakage_alarm')}>
              <a href="#/AVANIoT/PageWaterLeakage">
                <img
                  style={{ width: 30, height: 30, margin: 'auto' }}
                  src={alarmIcon}
                  alt="fireSpot"
                />
              </a>
            </Tooltip>
          </Col>
          <Col span={2} />
          <Hidden smDown>
            <Col span={18}>
              <Timer
                initialTime={countdown}
                direction="backward"
                ref={tiRef}
                startImmediately={false}
                onStart={() => {
                  setPause(true);
                }}
                checkpoints={[
                  {
                    time: 30 * 60 * 1000 - 50,
                    callback: () => setPause(true)
                  },
                  {
                    time: 100,
                    callback: () => setPause(false)
                  },
                  {
                    time: 0,
                    callback: function (e) {
                      tiRef.current.reset();
                    }
                  }
                ]}>
                {({ start }) => (
                  <Row gutter={16} justify="center" align="middle">
                    <Col span={24} style={{ margin: 'auto' }}>
                      <Tooltip title={t('water_leakage_alarm_pause')}>
                        <Button
                          color="inherit"
                          style={{ borderColor: '#f8f9ff' }}
                          onClick={start}>
                          <VolumeMute size="22" />
                          <Timer.Minutes />
                          {t('timer_min')} &nbsp;
                          <Timer.Seconds />
                          {t('timer_sec')}
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row>
                )}
              </Timer>
            </Col>
          </Hidden>
        </Row>
      </div>
    </>
  );
}
