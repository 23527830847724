import axios from 'axios';
import commonVariables from '../config/commonSetting';
import AuthService from './auth.service';
const hostname = commonVariables.hostname;

class CompanyPropService {
  setInformation(response) {
    if (response) {
      // console.log(response.data);
      this.setCanRouteCompanies(response.data.companyList);
      this.setCanRouteProperties(response.data.propertyList);
      this.setCurrentCompany(response.data.companyList[0]);
      this.setCurrentProperty(response.data.companyList[0].propertyList[0]);
    }
  }
  getInformationFromBackend(accessToken) {
    if (!accessToken) {
      accessToken = AuthService.getCurrentUser().accessToken;
      if (!accessToken) {
        console.log('Not logined, halt!');
        return;
      }
    }
    axios
      .get(hostname + '/user/getCompProp', {
        params: { accessToken }
      })
      .then((response) => {
        this.setInformation(response);
      })
      .catch((err) => {
        console.log('Error: Cannot get the latest information!', err);
      });
  }

  setCurrentCompany(company) {
    localStorage.setItem('currentCompany', JSON.stringify(company));
  }
  setCurrentProperty(property) {
    localStorage.setItem('currentProperty', JSON.stringify(property));
  }

  setCanRouteCompanies(companylist) {
    localStorage.setItem('canRouteCompanies', JSON.stringify(companylist));
  }
  setCanRouteProperties(propertylist) {
    localStorage.setItem('canRouteProperties', JSON.stringify(propertylist));
  }

  getCurrentCompany() {
    if (localStorage.getItem('currentCompany'))
      try {
        // console.log(localStorage.getItem('currentCompany'));
        return JSON.parse(localStorage.getItem('currentCompany'));
      } catch {
        return {
          company: 'compNotSet',
          company_name_en: 'Read Comp error?',
          company_name_tc: '讀取公司資料錯誤?',
          company_name_sc: '讀取公司資料錯誤?',
          company_logo: null,
          propertyList: [
            {
              company: 'compNotSet',
              property: 'propNotSet',
              property_name_en: 'Property Error?',
              property_name_tc: '讀取物業資料錯誤?',
              property_name_sc: '讀取物業資料錯誤?',
              property_logo: null
            }
          ]
        };
      }
    else return null;
  }
  getCurrentProperty() {
    if (localStorage.getItem('currentProperty'))
      try {
        // console.log(localStorage.getItem('currentProperty'));
        return JSON.parse(localStorage.getItem('currentProperty'));
      } catch {
        return {
          company: 'compNotSet',
          property: 'propNotSet',
          property_name_en: 'Read Property Error?',
          property_name_tc: '讀取物業資料錯誤?',
          property_name_sc: '讀取物業資料錯誤?',
          property_logo: null
        };
      }
    else return null;
  }

  getCanRouteCompanies() {
    // console.log(localStorage.getItem('canRouteCompanies'));
    return JSON.parse(localStorage.getItem('canRouteCompanies'));
  }
  getCanRouteProperties() {
    // console.log(localStorage.getItem('canRouteProperties'));
    return JSON.parse(localStorage.getItem('canRouteProperties'));
  }

  getCompByCompId(targetcompanyId) {
    if (!targetcompanyId) return -1;
    // console.log(targetcompany);
    let companies = this.getCanRouteCompanies();
    // console.log(companies)
    let targetIndex = companies.findIndex((company) => {
      return company.company == targetcompanyId;
    });
    // console.log('Line 30', properties, targetproperty, targetIndex);
    return companies[targetIndex];
  }
  getPropByPropId(targetpropId) {
    if (!targetpropId) return -1;
    let properties = this.getCanRouteProperties();
    let targetIndex = properties.findIndex((property) => {
      return property.property == targetpropId;
    });
    // console.log('Line 30', properties, targetproperty, targetIndex);
    return properties[targetIndex];
  }
  getGivenPropertyIDinList(targetpropId) {
    if (!targetpropId) return -1;
    let properties = this.getCanRouteProperties();
    let targetIndex = properties.findIndex((property) => {
      return property.property == targetpropId.property;
    });
    // console.log('Line 30', properties, targetproperty, targetIndex);
    return targetIndex;
  }
}

export default new CompanyPropService();
