import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import 'antd/dist/antd.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGears,
  faGauge,
  faTable,
  faMagnifyingGlassChart,
  faExclamation
} from '@fortawesome/free-solid-svg-icons';
/////for login
import AuthService from '../../services/auth.service';

import clsx from 'clsx';
import { ChevronRight } from 'react-feather';
import { useTranslation } from 'react-i18next';
import SidebarUserbox from '../SidebarUserbox';
import { Collapse } from 'reactstrap';

///////////////////

import commonVariables from '../../config/commonSetting';
import PageManager from '../../components/PageManager';
import { useInterval } from '../../config/commonFunction';
const hostname = commonVariables.hostname;

const languageMap = commonVariables.languageMap;

const SidebarMenu = (props) => {
  // const currentUser = authService.getCurrentUser();
  // const [pageAccessible, setPageAccessible] = useState({});
  // useEffect(() => {
  //   let preparedData = { accessToken: currentUser["accessToken"] };
  //   axios.post("http://" + window.location.hostname + ":5000/users/checkPermit", preparedData)
  //     .then(result => {
  //       console.log(result);
  //       setPageAccessible(result.data);
  //     }).catch(error => {
  //       console.log('ERR: ' + error + " : " + error.response.data);
  //     });

  // }, []);
  let currentUser = AuthService.getCurrentUser();

  const selectedLang = localStorage.getItem('i18nextLng') || 'en';
  const { t } = useTranslation();
  const [permissions, setPermissions] = useState({});

  const loadMaps = () => {
    // console.log(currentUser);
    if (currentUser) {
      const url = hostname + '/user/accessible/';
      axios
        .get(url, { params: { accessToken: currentUser?.accessToken || null } })
        .then((result) => {
          console.log('User level permission', result);
          if (result.data) {
            let rx_permission = result.data;
            PageManager.setPagesAccessible(rx_permission);
            setPermissions(rx_permission);
            combineListToHtmls(
              PageManager.findAccordingToCategories('home'),
              sethome_tags,
              rx_permission
            );
            combineListToHtmls(
              PageManager.findAccordingToCategories('setup'),
              setsetup_tags,
              rx_permission
            );

            combineListToHtmls(
              PageManager.findAccordingToCategories('reportsys'),
              setreportsys_tags,
              rx_permission
            );

            combineListToHtmls(
              PageManager.findAccordingToCategories('device'),
              setdevice_tags,
              rx_permission
            );

            combineListToHtmls(
              PageManager.findAccordingToCategories('activity_log'),
              setactlog_tags,
              rx_permission
            );

            combineListToHtmls(
              PageManager.findAccordingToCategories('analysis'),
              setanalysis_tags,
              rx_permission
            );

            combineListToHtmls(
              PageManager.findAccordingToCategories('home_end'),
              sethome_end_tag,
              rx_permission
            );
          } else {
            console.log('Cannot received Permission from the backend server');
          }
        })
        .catch((err) => {
          console.log('Error: ', err);
        });
    } else {
      // Redirect to Home Login.
      window.location = window.location.origin;
    }
  };

  let combineListToHtmls = (lists, catesetupFunction, rx_permission) => {
    // console.log(currentUser.level==0);
    let finishedTags = lists.map((item, index) => {
      // console.log(item);
      return (
        (parseInt(rx_permission[item.page]) == 2 ||
          parseInt(rx_permission[item.page]) == 1 ||
          currentUser.level == 0) && (
          <li key={index}>
            <NavLink onClick={toggleSidebarMobile} to={'/' + item.page}>
              <span className="sidebar-icon">{item.icon}</span>
              {t(item.pagename)}
            </NavLink>
          </li>
        )
      );
    });
    // console.log(finishedTags);
    catesetupFunction(finishedTags);
  };
  const [menuAnchor, setMenuAnchor] = useState(null);
  useEffect(() => {
    document.body.dir = languageMap[selectedLang].dir;

    loadMaps();
  }, [menuAnchor, selectedLang]);

  const { setSidebarToggleMobile, sidebarUserbox } = props;

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  const [home_tags, sethome_tags] = useState(<>...Loading...</>);
  const [setup_tags, setsetup_tags] = useState(<>...Loading...</>);
  const [reportsys_tags, setreportsys_tags] = useState(<>...Loading...</>);
  const [device_tags, setdevice_tags] = useState(<>...Loading...</>);
  const [actlog_tags, setactlog_tags] = useState(<>...Loading...</>);
  const [analysis_tags, setanalysis_tags] = useState(<>...Loading...</>);
  const [home_end_tag, sethome_end_tag] = useState(<>...Loading...</>);

  const [dashboardOpen, setDashboardOpen] = useState(true);
  const toggleDashboard = (event) => {
    setDashboardOpen(!dashboardOpen);
    event.preventDefault();
  };

  //////////////////////////////////////////////////////////////////
  const [DeviceOpen, setDeviceOpen] = useState(false);
  const toggleDevice = (event) => {
    setDeviceOpen(!DeviceOpen);
    event.preventDefault();
  };

  const [ReportOpen, setReportOpen] = useState(false);
  const toggleReport = (event) => {
    setReportOpen(!ReportOpen);
    event.preventDefault();
  };

  const [SetupOpen, setSetupOpen] = useState(false);
  const toggleSetup = (event) => {
    setSetupOpen(!SetupOpen);
    event.preventDefault();
  };

  const [ActivityLogOpen, setActivityLogOpen] = useState(false);
  const toggleActivityLog = (event) => {
    setActivityLogOpen(!ActivityLogOpen);
    event.preventDefault();
  };

  const [DetailOpen, setDetailOpen] = useState(false);
  const toggleDetail = (event) => {
    setDetailOpen(!DetailOpen);
    event.preventDefault();
  };

  const [AnalysisOpen, setAnalysisOpen] = useState(false);
  const toggleAnalysis = (event) => {
    setAnalysisOpen(!AnalysisOpen);
    event.preventDefault();
  };

  const [EntranceDashboardOpen, setEntranceDashboardOpen] = useState(false);
  const toggleEntranceDashboard = (event) => {
    setEntranceDashboardOpen(!EntranceDashboardOpen);
    event.preventDefault();
  };

  const [elementsOpen, setElementsOpen] = useState(false);
  const toggleElements = (event) => {
    setElementsOpen(!elementsOpen);
    event.preventDefault();
  };

  const [pagesOpen, setPagesOpen] = useState(false);
  const togglePages = (event) => {
    setPagesOpen(!pagesOpen);
    event.preventDefault();
  };

  const [otherPagesOpen, setOtherPagesOpen] = useState(false);
  const toggleOtherPages = (event) => {
    setOtherPagesOpen(!otherPagesOpen);
    event.preventDefault();
  };

  const [applicationOpen, setApplicationOpen] = useState(false);
  const toggleApplication = (event) => {
    setApplicationOpen(!applicationOpen);
    event.preventDefault();
  };

  const [designSystemOpen, setDesignSystemOpen] = useState(false);
  const toggleDesignSystem = (event) => {
    setDesignSystemOpen(!designSystemOpen);
    event.preventDefault();
  };

  const [blocksOpen, setBlocksOpen] = useState(false);
  const toggleBlocks = (event) => {
    setBlocksOpen(!blocksOpen);
    event.preventDefault();
  };

  const [levelsOpen, setLevelsOpen] = useState(false);
  const toggleLevels = (event) => {
    setLevelsOpen(!levelsOpen);
    event.preventDefault();
  };

  const [widgetsOpen, setWidgetsOpen] = useState(false);
  const toggleWidgets = (event) => {
    setWidgetsOpen(!widgetsOpen);
    event.preventDefault();
  };

  const [chartsOpen, setChartsOpen] = useState(false);
  const toggleCharts = (event) => {
    setChartsOpen(!chartsOpen);
    event.preventDefault();
  };

  const [uiKitComponentsOpen, setUiKitComponents] = useState(false);
  const toggleUiKitComponents = (event) => {
    setUiKitComponents(!uiKitComponentsOpen);
    event.preventDefault();
  };

  const [formsComponentsOpen, setFormsComponents] = useState(false);
  const toggleFormsComponents = (event) => {
    setFormsComponents(!formsComponentsOpen);
    event.preventDefault();
  };

  const [collapsedLayoutOpen, setCollapsedLayoutOpen] = useState(false);
  const toggleCollapsedLayout = (event) => {
    setCollapsedLayoutOpen(!collapsedLayoutOpen);
    event.preventDefault();
  };

  const [pagesLoginOpen, setPagesLoginOpen] = useState(false);
  const togglePagesLogin = (event) => {
    setPagesLoginOpen(!pagesLoginOpen);
    event.preventDefault();
  };

  const [pagesRegisterOpen, setPagesRegisterOpen] = useState(false);
  const togglePagesRegister = (event) => {
    setPagesRegisterOpen(!pagesRegisterOpen);
    event.preventDefault();
  };

  const [pagesRecoverOpen, setPagesRecoverOpen] = useState(false);
  const togglePagesRecover = (event) => {
    setPagesRecoverOpen(!pagesRecoverOpen);
    event.preventDefault();
  };

  const [unhandledLog, setUnhandledLog] = useState(0);

  function getUnhandledLog() {
    const url = hostname + '/api/getUnhandledActivityLog';
    axios
      .get(url)
      .then((results) => {
        var data = results.data;
        setUnhandledLog(data);
        console.log('badge: ', data);
      })
      .catch((error) => {
        console.log('ERR: ' + error);
      });
  }

  useEffect(() => {
    // getUnhandledLog();
  }, []);

  useInterval(() => {
    // getUnhandledLog();
  }, 5 * 1000);

  //Sensor Status for side-bar badge dot
  const water_warning = false;
  const door_warning = false;
  const soap_warning = false;
  const hpaper_warning = false;
  const iaq_warning = false;
  const smart_warning = false;

  return (
    <>
      <PerfectScrollbar>
        {sidebarUserbox && <SidebarUserbox />}
        <div className="sidebar-navigation">
          <ul>
            {/* <li>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                to="/PageHome">
                <span className="sidebar-icon">
                  <Home />
                </span>
                {t('sidebar_home')}
              </NavLink>
            </li> */}
            {home_tags}

            {permissions.showSetupTag && (
              <li>
                <a
                  //href="/WaterLeakage#/WaterLeakage/PageNotSupport" //demo version
                  href="#/"
                  onClick={toggleSetup}
                  className={clsx({ active: SetupOpen })}>
                  <span className="sidebar-icon">
                    <FontAwesomeIcon icon={faGears} />
                  </span>
                  <span className="sidebar-item-label">
                    {t('sidebar_setup')}
                  </span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={SetupOpen}>
                  <ul>
                    {/* <li>
                      <NavLink
                        onClick={toggleSidebarMobile}
                        to="/PageWaterLeakage">
                        {t('sidebar_main_gate')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleSidebarMobile}
                        to="/PageDeveloping">
                        {t('sidebar_end_point')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleSidebarMobile}
                        to="/PageDeveloping">
                        {t('sidebar_pair_up')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleSidebarMobile}
                        to="/PageDeveloping">
                        {t('sidebar_map')}
                      </NavLink>
                    </li> */}

                    {setup_tags}
                  </ul>
                </Collapse>
              </li>
            )}
            {permissions.showReportsysTag && (
              <li>
                <a
                  href="#/"
                  onClick={toggleReport}
                  className={clsx({ active: ReportOpen })}>
                  <span className="sidebar-icon">
                    <FontAwesomeIcon icon={faExclamation} />
                  </span>
                  <span className="sidebar-item-label">
                    {t('sidebar_workform')}
                  </span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={ReportOpen}>
                  <ul>{reportsys_tags}</ul>
                </Collapse>
              </li>
            )}
            {permissions.showDeviceTag && (
              <li>
                <a
                  href="#/"
                  onClick={toggleDevice}
                  className={clsx({ active: DeviceOpen })}>
                  <span className="sidebar-icon">
                    <FontAwesomeIcon icon={faGauge} />
                  </span>
                  <span className="sidebar-item-label">
                    {t('sidebar_device')}
                  </span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={DeviceOpen}>
                  <ul>{device_tags}</ul>
                </Collapse>
              </li>
            )}

            {permissions.showLogTag && (
              <li>
                <a
                  href="#/"
                  onClick={toggleActivityLog}
                  className={clsx({ active: ActivityLogOpen })}>
                  <span className="sidebar-icon">
                    <FontAwesomeIcon icon={faTable} />
                  </span>
                  <span className="sidebar-item-label">
                    {t('sidebar_activity_log')}
                  </span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={ActivityLogOpen}>
                  <ul>{actlog_tags}</ul>
                </Collapse>
              </li>
            )}

            {permissions.showAnalysisTag && (
              <li>
                <a
                  href="#/"
                  onClick={toggleAnalysis}
                  className={clsx({ active: AnalysisOpen })}>
                  <span className="sidebar-icon">
                    <FontAwesomeIcon icon={faMagnifyingGlassChart} />
                  </span>
                  <span className="sidebar-item-label">
                    {t('sidebar_analysis')}
                  </span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={AnalysisOpen}>
                  <ul>{analysis_tags}</ul>
                </Collapse>
              </li>
            )}
            {home_end_tag}
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
