import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import {
  Badge,
  UncontrolledTooltip,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';
import property_logo from './tree-city-square.png';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import CompanyPropService from '../../services/companyprop.service';

import commonVariables from '../../config/commonSetting';
import { Col, Row } from 'antd';
const { Option } = Select;
const hostname = commonVariables.hostname;

const SidebarUserbox = () => {
  const { t } = useTranslation();
  const selectedLang = localStorage.getItem('i18nextLng') || 'en';

  // let currentUser = AuthService.getCurrentUser();
  let currentCompProp = CompanyPropService;
  //Favor Industrial Centre
  let [propertyName, setPropertyName] = useState('...Loading...');
  let [propertyList, setpropertyList] = useState([]);

  let [propLogo, setPropLogo] = useState(property_logo);

  let changeProperty = (property) => {
    let displayPropertyName = '...Loading...';

    if (selectedLang === 'en') {
      displayPropertyName = property.property_name_en;
    } else if (selectedLang === 'tc') {
      displayPropertyName = property.property_name_tc;
    } else if (selectedLang === 'sc') {
      displayPropertyName = property.property_name_sc;
    } else {
      displayPropertyName = property.property_name_en;
    }
    setPropertyName(displayPropertyName);
    if (
      !(
        property.property_logo === '' ||
        property.property_logo === undefined ||
        property.property_logo === null
      )
    ) {
      setPropLogo(hostname + '/property/icon/' + property.property_logo);
    }
  };

  useEffect(() => {
    // console.log(currentCompProp.getCanRouteProperties());
    changeProperty(currentCompProp.getCurrentProperty());
    setpropertyList(currentCompProp.getCanRouteProperties());
  }, [currentCompProp, selectedLang]);

  const handleChangeProperty = function (property) {
    // console.log("Property changed");
    // console.log(JSON.stringify(property));

    currentCompProp.setCurrentProperty(property);
    if (currentCompProp.getCurrentCompany().company != property.company) {
      currentCompProp.setCurrentCompany(
        currentCompProp.getCompByCompId(property.company)
      );
    }
    // changeProperty(property);
    window.location.reload();
  };

  return (
    <>
      <div className="app-sidebar--userbox">
        <UncontrolledDropdown className="card-tr-actions">
          <DropdownToggle
            color="link"
            className="p-0 border-0 d-30 text-white-50">
            <FontAwesomeIcon
              icon={['fas', 'ellipsis-h']}
              className="font-size-lg"
            />
          </DropdownToggle>

          <DropdownMenu
            right
            className="text-nowrap dropdown-menu-lg overflow-hidden p-0">
            <div className="align-box-row align-items-center p-3">
              <div className="avatar-icon-wrapper avatar-icon-md">
                <div className="avatar-icon rounded-circle">
                  <img alt="..." src={propLogo} />
                </div>
              </div>
              <div className="pl-2">
                <span style={{ color: '#3b5999', fontWeight: 'bold' }}>
                  {propertyName}
                </span>
                {/* <Badge color="success">{t('active')}</Badge> */}
              </div>
            </div>

            <div className="divider" />
            <div className="d-block rounded-bottom py-3 text-center">
              <Row gutter={[0, 16]}>
                {propertyList.map((property, key) => (
                  <Col span={12} key={key}>
                    <Button id={'prop-' + key} color="facebook">
                      <span
                        className="btn-wrapper--icon"
                        onClick={() => {
                          handleChangeProperty(property);
                        }}>
                        <FontAwesomeIcon icon={faHouse} size="lg" />
                      </span>
                    </Button>
                    <div style={{ fontSize: '12px' }}>
                      {(selectedLang === 'en' && (
                        <>{property.property_name_en}</>
                      )) ||
                        (selectedLang === 'tc' && (
                          <>{property.property_name_tc}</>
                        )) ||
                        (selectedLang === 'sc' && (
                          <>{property.property_name_sc}</>
                        )) || <>{property.property_name_en}</>}
                    </div>
                    <UncontrolledTooltip target={'prop-' + key}>
                      {(selectedLang === 'en' && (
                        <>{property.property_name_en}</>
                      )) ||
                        (selectedLang === 'tc' && (
                          <>{property.property_name_tc}</>
                        )) ||
                        (selectedLang === 'sc' && (
                          <>{property.property_name_sc}</>
                        )) || <>{property.property_name_en}</>}
                    </UncontrolledTooltip>
                  </Col>
                ))}
              </Row>
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>

        {/* <div className="avatar-icon-wrapper avatar-icon-md">
          <div className="avatar-icon rounded-circle">
            <img alt="..." src={propLogo} />
          </div>
        </div> */}
        <div
          style={{ fontWeight: 'bold', marginTop: '10px', fontSize: '18px' }}>
          {propertyName}
          {/* <small className="d-block text-white-50">
            2 Kin Hong Street, Kwai Chung
          </small> */}
        </div>
        {/* <Button size="sm" tag={NavLink} to="/PageProfile" color="userbox">
          View profile
        </Button> */}
      </div>
    </>
  );
};

export default SidebarUserbox;
