import axios from 'axios';
import commonVariables from '../config/commonSetting';
import CompanyPropService from './companyprop.service';
import CustomizedProp from './customizedprop.service';

const hostname = commonVariables.hostname;

class AuthService {
  constructor() {
    this.currentCompProp = CompanyPropService;
    this.currentCustomizedProp = CustomizedProp;
  }
  login(emp_mail, emp_password) {
    return new Promise((resolve, reject) => {
      axios
        .post(hostname + '/auth/login', {
          emp_mail,
          emp_password
        })
        .then((response) => {
          if (response.data.accessToken) {
            let data = response.data;
            let stringifydata = JSON.stringify(data);
            // console.log(response.data);
            localStorage.setItem('user', stringifydata);
            // console.log(response.data.companyList);
            // Handle Company and Property
            // console.log(response.data.companyList);
            this.currentCompProp.setInformation(response);
            this.currentCustomizedProp.setInformation(response);
            // console.log(data);
            resolve(data.default_login);
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.data) reject(err.response);
            else reject(err.response.data);
          } else reject(err.message);
        });
    });
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.setItem('currentCompany', '{}');
    localStorage.setItem('currentProperty', '{}');
    localStorage.setItem('canRouteCompanies', '{}');
    localStorage.setItem('canRouteProperties', '{}');
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
}

export default new AuthService();
