import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';
import './i18nextConf';

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <HashRouter basename="/AVANIoT/">
      <App />
    </HashRouter>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
