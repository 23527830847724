import React from 'react';
import LivePreviewExample2 from './test_footer.js';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { Nav, NavItem } from 'reactstrap';
import { NavLink as NavLinkStrap } from 'reactstrap';

import { connect } from 'react-redux';

const { Paragraph } = Typography;
const Footer = (props) => {
  const { footerShadow, footerBgTransparent } = props;
  return (
    <>
      <div
        className={clsx('app-footer text-black-50', {
          'app-footer--shadow': footerShadow,
          'app-footer--opacity-bg': footerBgTransparent
        })}>
        <div className="app-footer--first">
          {/* <Nav>
            <NavItem>
              <NavLinkStrap
                tag={Link}
                to="/DashboardAnalytics"
                className="rounded-sm">
                Analytics
              </NavLinkStrap>
            </NavItem>
            <NavItem>
              <NavLinkStrap
                tag={Link}
                to="/DashboardStatistics"
                className="rounded-sm">
                Statistics
              </NavLinkStrap>
            </NavItem>
            <NavItem>
              <NavLinkStrap tag={Link} to="/Overview" className="rounded-sm">
                Overview
              </NavLinkStrap>
            </NavItem>
          </Nav> */}
        </div>
        <div className="app-footer--second">
          {/* <div style={{ textAlign: 'right' }}>
            Tel: +852 39973955 | Email: info@avlfs.com
          </div> */}

          <div>
            2022 © All Rights Reserved By{' '}
            <span>
              {' '}
              <a href="https://www.avanlite.com/" target="_blank">
                AVL Facility Services Holding Limited
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  footerShadow: state.ThemeOptions.footerShadow,
  footerBgTransparent: state.ThemeOptions.footerBgTransparent
});

export default connect(mapStateToProps)(Footer);
