import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AuthService from './auth.service';
import axios from 'axios';
import { notification } from 'antd';
import { Button } from 'reactstrap';
import SweetAlert from 'sweetalert2-react';
import { getPagePermission } from '../config/commonFunction';
import commonVariables from '../config/commonSetting';
import { Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast, Slide, Flip, Zoom } from 'react-toastify';
import moment from 'moment';
const hostname = commonVariables.hostname;

const CheckPermission = () => {
  const [modal4, setModal4] = useState(false);
  const toggle = () => setModal4(!modal4);

  const notify_login = () => {
    toast.error('Login Failure or Login session timeout. Please login again.', {
      position: 'top-right',
      
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      containerId: 'B',
      closeButton: (
        <Button
          onClick={() => {
            history.push('/PageLogin');
          }}>
          Login
        </Button>
      )
    });
  };

  const notify_support = () => {
    toast.warn('Oh no, you do not have permission to access this page.', {
      position: 'top-right',
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      containerId: 'B'
    });
  };

  const notify_pw_expired = () => {
    toast.warn(
      'Your password has been expired at ' +
        new Date(currentUser.emp_password_exp_date).toLocaleString() +
        '. Please update your password as soon as possible.',
      {
        position: 'top-right',
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        containerId: 'B'
        // closeButton: (
        //   <Button
        //     onClick={() => {
        //       history.push('/PageLogin');
        //     }}>
        //     Login
        //   </Button>
        // )
      }
    );
  };

  //Used in src\layout-components\Sidebar
  const history = useHistory();
  const currentUser = AuthService.getCurrentUser();
  const currentPath = useLocation();

  useEffect(() => {
    // console.log(currentPath);
    if (currentUser) {
      // #region check password expired
      // console.log('Line 95', currentUser, currentUser.emp_password_exp_date);
      // if (moment(currentUser.emp_password_exp_date) < moment()) {
      //   notify_pw_expired();
      // }
      // #endregion
    }
    if (
      currentUser &&
      !(
        currentPath.pathname == '/PageLogin' ||
        currentPath.pathname == '/PageHome'
      )
    ) {
      let preparedData = { accessToken: currentUser['accessToken'] };
      // This check logined.
      let url = hostname + '/auth/checkPermit';
      axios
        .post(url, preparedData)
        .then((result) => {
          getPagePermission((pagepermission) => {
            // console.log(pagepermission);
            if (!pagepermission.read) {
              console.log('Not Authorized.');
              let debug = false;
              if (debug) {
                const args = {
                  message: 'Unauthorized',
                  description:
                    'You do not have permission to access this page.',
                  duration: 4,
                  placement: 'topLeft'
                };

                //notification.open(args);
                notify_support();
                setTimeout(() => {
                  history.push('/PageNotSupport');
                  window.location.reload();
                }, 20000);
              } else {
                history.push('/PageNotSupport');
                window.location.reload();
              }
            } else {
              // console.log('Can visit');
            }
          });
        })
        .catch((error) => {
          if (error.response != undefined) {
            console.log('ERR: ' + error + ' : ' + error.response.data);
          } else {
            console.log('ERR: ' + error + ' : ' + error.response);
          }

          const args = {
            message: 'Login Failure or Login session timeout',
            description: (
              <>
                Please{' '}
                <Button
                  onClick={() => {
                    history.push('/PageLogin');
                  }}>
                  login again
                </Button>
              </>
            ),
            placement: 'topLeft',
            duration: 0
          };
          //notification.open(args);
          notify_login();

          // setTimeout(() => {
          //   AuthService.logout();
          //   history.push('/PageLogin');
          //   window.location.reload();
          // }, 5000);
        });
    } else {
      // history.push('/PageLogin');
      // window.location.reload();
    }
  }, [currentPath]);

  return (
    <>
      {/* {(() => {
        notify_login();
        notify_support();
      })()} */}
    </>
  );
};

export default CheckPermission;
