import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { connect } from 'react-redux';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import HeaderDots from '../../layout-components/HeaderDots';
import HeaderUserbox from '../HeaderProperty';
import HeaderSearch from '../../layout-components/HeaderSearch';
import HeaderMenu from '../../layout-components/HeaderMenu';

import { Row, Col, Divider, Space, notification, Button } from 'antd';
import Logo from './tree-city-square.png';

//import background from
// import AuthService from '../../services/auth.service';
import CompanyPropService from '../../services/companyprop.service';

import commonVariables from '../../config/commonSetting';
const hostname = commonVariables.hostname;

/////notif
//////////////noti
const sensor_location = 'position A';
const sensor_name = 'Sensor A';

const openNotification = (placement) => {
  notification.error({
    message: `Warning`,
    description: (
      <div>
        location: {sensor_location}
        <br />
        Sensor: {sensor_name} is warning
      </div>
    ),
    placement,
    duration: 0,
    type: 'error',
    style: {
      backgroundColor: '#fff1f0'
    }
  });
};
const Header = (props) => {
  // let currentUser = AuthService.getCurrentUser();
  let currentCompProp = CompanyPropService;

  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile
  } = props;

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  let [comLogo, setComLogo] = useState(Logo);

  // Location for company logo (company_logo)
  useEffect(() => {
    // console.log(currentCompProp.getCurrentCompany());
    if (
      !(
        currentCompProp.getCurrentCompany().company_logo === '' ||
        currentCompProp.getCurrentCompany().company_logo === undefined ||
        currentCompProp.getCurrentCompany().company_logo === null
      )
    ) {
      axios
        .get(
          hostname +
            '/company/icon/' +
            currentCompProp.getCurrentCompany().company_logo,
          { responseType: 'blob' }
        )
        .then((result) => {
          var reader = new window.FileReader();
          reader.readAsDataURL(result.data);
          reader.onload = function () {
            var imageDataUrl = reader.result;
            setComLogo(imageDataUrl);
          };
        });
    }
  }, []);

  return (
    <>
      {/* Here is header.... we are using ES5 */}

      <Space direction="vertical">
        <Row gutter={16}>
          <Col span={24}>
            <div
              className={clsx('app-header', {
                'app-header--shadow': headerShadow,
                'app-header--opacity-bg': headerBgTransparent
              })}>
              <div className="app-header--pane">
                <button
                  className={clsx(
                    'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
                    { 'is-active': sidebarToggleMobile }
                  )}
                  onClick={toggleSidebarMobile}>
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>

                {/* <HeaderSearch />*/}

                <img src={comLogo} style={{ height: '56px', marginLeft: 10 }} />
                {/* <HeaderMenu /> */}
              </div>

              <div className="app-header--pane">
                <HeaderDots />
                <HeaderUserbox />
                {/* <HeaderDrawer /> */}
              </div>
            </div>
          </Col>
        </Row>
      </Space>
    </>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
