import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./language_json/en/en.json";
import translationTC from "./language_json/tc/tc.json";
import translationSC from "./language_json/sc/sc.json";
import translationTH from "./language_json/th/th.json";

const fallbackLng = ["en"];
const availableLanguages = ["en", "tc", "sc", "th"];

const resources = {
  en: {
    translation: translationEN
  },
  tc: {
    translation: translationTC
  },
  sc: {
    translation: translationSC
  },
  th: {
    translation: translationTH
  },

};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
