import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Row,
  Col,
  UncontrolledPopover,
  Badge,
  Nav,
  NavItem,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';
import { NavLink as NavLinkStrap } from 'reactstrap';
import { Settings, Grid, Users, Layers } from 'react-feather';
import { Breadcrumb, Layout, Menu } from 'antd';

import { Bell, Briefcase, LifeBuoy } from 'react-feather';
const { Header, Content, Footer } = Layout;

const header = {
  fontWeight: 'bold',
  color: '#1da1f2'
};
const HeaderMenu = () => {
  return (
    <>
      <div className="header-nav-menu d-none d-lg-block">
        <ul className="d-flex justify-content-center">
          <li>
            <a
              className="rounded-sm"
              href="#/"
              onClick={(e) => e.preventDefault()}>
              <span style={header}>
                IoT Smart Solution
              </span>
              <span className="opacity-5 dropdown-arrow">
                <FontAwesomeIcon icon={['fas', 'angle-down']} />
              </span>
            </a>
            <div className="submenu-dropdown">
              <div className="shadow-sm-dark w-100 bg-second rounded p-3">
                <Nav
                  pills
                  className="nav-transparent-alt nav-lg flex-column p-0">
                  <NavItem className="py-1">
                    <NavLinkStrap
                      href="#/"
                      onClick={(e) => e.preventDefault()}
                      className="px-3 text-white-50">
                      <div className="d-flex">
                        <Bell className="text-white-50" />
                        <div className="pl-3 text-white">
                          <div className="font-weight-bold">Smart Toilet</div>
                          <div className="text-white-50 font-size-sm">
                            IoT, Big Data & Real-time Management
                          </div>
                        </div>
                      </div>
                    </NavLinkStrap>
                  </NavItem>
                  <NavItem className="py-1">
                    <NavLinkStrap
                      href="#/"
                      onClick={(e) => e.preventDefault()}
                      className="px-3 text-white-50">
                      <div className="d-flex">
                        <LifeBuoy className="text-white-50" />
                        <div className="pl-3 text-white">
                          <div className="font-weight-bold">
                            IoT Lighting
                            <span className="ml-3 badge badge-info">Soon</span>
                          </div>
                          <div className="text-white-50 font-size-sm">
                            Energy saving, better control
                          </div>
                        </div>
                      </div>
                    </NavLinkStrap>
                  </NavItem>
                  <NavItem className="py-1">
                    <NavLinkStrap
                      href="#/"
                      onClick={(e) => e.preventDefault()}
                      className="px-3 text-white-50">
                      <div className="d-flex">
                        <Settings className="text-white-50" />
                        <div className="pl-3 text-white">
                          <div className="font-weight-bold">
                            Water Leakage
                            <span className="ml-3 badge badge-info">Soon</span>
                          </div>
                          <div className="text-white-50 font-size-sm">
                            Earlier alert, earlier follow up
                          </div>
                        </div>
                      </div>
                    </NavLinkStrap>
                  </NavItem>
                </Nav>
              </div>
            </div>
          </li>
          <li>
            <a
              className="rounded-sm"
              href="#/"
              onClick={(e) => e.preventDefault()}>
              <span style={header}>Car Park System</span>
            </a>
          </li>
          <li>
            <a
              className="rounded-sm"
              href="#/"
              onClick={(e) => e.preventDefault()}>
              <span style={header}>Security System</span>
            </a>
          </li>
          {/* <li>
            <a
              className="rounded-sm"
              href="#/"
              onClick={(e) => e.preventDefault()}>
              Blocks
            </a>
          </li> */}
        </ul>
      </div>
    </>
  );
};

export default HeaderMenu;