import axios from 'axios';
import AuthService from '../services/auth.service';
import CompanyPropService from '../services/companyprop.service';
import { useLocation } from 'react-router-dom';
import commonVariables from './commonSetting';

import React from 'react';
import { useEffect, useRef } from 'react';
import { Row } from 'antd';

const currentUser = AuthService.getCurrentUser();
const hostname = commonVariables.hostname;

function getCompany(
  selectedLang,
  setOneCompanyMode,
  setCompany,
  setCascader,
  setMoreThanOneProperty,
  nextFunction

  /* prepared_data = {
        company: value[0],
        property: value[1]
      }; */
) {
  const url = hostname + '/company/getCompany';
  // console.log("currentUser",currentUser);
  axios
    .post(url, {
      accessToken: currentUser ? currentUser.accessToken : null || null,
      selectedLang: selectedLang
    })
    .then((results) => {
      // setCompany(dropdown);
      if (results.data[1]) {
        if (setOneCompanyMode) setOneCompanyMode(true);
        let usercompany = results.data[1];
        setCompany(usercompany);
        if (results.data[0].length > 1) {
          setCascader(results.data[0]);
        } else if (results.data[0].length == 1) {
          if (setMoreThanOneProperty) setMoreThanOneProperty(false);
        }
        let prepared_data = {
          company: usercompany,
          property: null
        };
        nextFunction(prepared_data);
      } else {
        // Admin mode
        let result_array = [...results.data[0]];
        let firstcompany = results.data[0][0];
        if (result_array.length > 1) {
          setCascader(result_array);
        } else if (result_array.length == 1) {
          if (setOneCompanyMode) setOneCompanyMode(true);
          setCompany(firstcompany.value);
          result_array = result_array[0].children;
          setCascader(result_array);
        }
        let prepared_data = {
          company: null,
          property: null
        };
        nextFunction(prepared_data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

function getCompanyFloor(selectedLang, setCascader, nextFunction) {
  const url = hostname + '/company/getCompanyFloor';
  // console.log("currentUser",currentUser);
  axios
    .post(url, {
      accessToken: currentUser ? currentUser.accessToken : null || null,
      selectedLang: selectedLang
    })
    .then((results) => {
      // setCompany(dropdown);
      {
        // Admin mode
        let result_array = [...results.data[0]];
        setCascader(result_array);

        let prepared_data = {
          company: null,
          property: null,
          floor: null
        };
        nextFunction(prepared_data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

let currentCompProp = CompanyPropService;
const current_Building = {
  company: currentCompProp.getCurrentCompany()
    ? currentCompProp.getCurrentCompany().company
    : null,
  property: currentCompProp.getCurrentProperty()
    ? currentCompProp.getCurrentProperty().property
    : null
};

function getCompanyFloorType(
  selectedLang,
  setCascader,
  type
  // nextFunction
) {
  const url = hostname + '/company/getCompanyFloorType';
  // console.log("currentUser",currentUser);
  let prepared_data = {
    accessToken: currentUser ? currentUser.accessToken : null || null,
    company: current_Building.company,
    property: current_Building.property,
    type: type,
    selectedLang: selectedLang
  };
  console.log(prepared_data);
  axios
    .post(url, prepared_data)
    .then((results) => {
      // setCompany(dropdown);
      {
        // Admin mode
        let result_array = [...results.data[0]];
        setCascader(result_array);

        // let prepared_data = {
        //   company: null,
        //   property: null,
        //   floor: null
        // };
        // nextFunction(prepared_data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

let getPagePermission = function (next) {
  const currentPath = window.location.hash;
  // console.log(currentPath);
  let lastItem = currentPath.split('/').pop();
  let currentUser = AuthService.getCurrentUser();

  // console.log(currentUser);
  if (currentUser) {
    const url = hostname + '/user/accessible/' + lastItem;
    // console.log(url);
    axios
      .get(url, {
        params: {
          accessToken: currentUser ? currentUser.accessToken : null || null
        }
      })
      .then((result) => {
        // console.log(result);
        next(result.data);
      })
      .catch((err) => {
        console.log('Error: ', err);
      });
  }
};

function findCommon(item, value) {
  return (
    item.floor == value.floor &&
    (item.location ==
      value.location.substring(0, value.location.indexOf('女廁')) + '廁所' ||
      item.location ==
        value.location.substring(0, value.location.indexOf('男廁'))) + '廁所'
  );
}

function removeGenderToilet(value) {
  return (
    value
      .replace('女廁', '')
      .replace('男廁', '')
      .replace('Female', '')
      .replace('Male', '') +
    (value.indexOf('女廁') != -1 || value.indexOf('男廁') != -1
      ? '廁所'
      : value.indexOf('Female') != -1 || value.indexOf('Male') != -1
      ? 'Toilet'
      : '')
  );
}

function removeGenderToiletBJAPM(value) {
  return (
    (value.indexOf('男厕') != -1 || value.indexOf('女厕') != -1 ? '厕所' : '') +
    value.replace('男厕', '').replace('女厕', '')
  );
}

function floorGenerator(
  index,
  floor,
  devices,
  column,
  cardGenerator,
  useKeyCTOSeparate = true
) {
  let rows = [];
  let property = '';
  if (floor !== '') {
    if (devices.hasOwnProperty('property')) property = devices.property;
  }

  let returnDict = {
    key: index,
    property: property,
    floor: floor,
    rawDevices: devices.list ? devices.list : [],
    devices: <Row></Row>,
    beforedevices: [],
    deviceListShow: false
  };
  if (useKeyCTOSeparate) {
    if (typeof devices.list != 'undefined') {
      if (devices.list.length > 0) {
        for (let dev of devices.list) {
          if (dev.key_c != '') {
            if (!returnDict.hasOwnProperty('devices' + dev.key_c)) {
              returnDict['rawDevices' + dev.key_c] = [];
              returnDict['devices' + dev.key_c] = [];
            }
            returnDict['rawDevices' + dev.key_c].push(dev);
            returnDict['devices' + dev.key_c].push(cardGenerator(dev));
          } else {
            rows.push(cardGenerator(dev));
            returnDict.beforedevices.push(dev);
          }
        }
      }
    }
    returnDict.deviceListShow = rows.length > 1 || column.length < 1;
    if (rows.length >= 1) {
      returnDict['devices'] = <Row>{rows}</Row>;
    }
    if (column) {
      for (let col of column) {
        returnDict['devices' + col] = <Row>{returnDict['devices' + col]}</Row>;
      }
    }
  } else {
    if (typeof devices.list != 'undefined') {
      if (devices.list.length > 0) {
        for (let dev of devices.list) {
          rows.push(cardGenerator(dev));
        }
        returnDict.deviceListShow = true;
        returnDict['devices'] = <Row>{rows}</Row>;
      }
    }
  }

  return returnDict;
}

let columnTranslateNames = {
  accessible_toilet: {
    tc: '暢通易達廁所',
    sc: '畅通易达厕所',
    en: 'Accessible Toilet'
  },
  male_toilet: {
    tc: '男廁',
    sc: '男厕',
    en: 'Male Toilet'
  },
  female_toilet: {
    tc: '女廁',
    sc: '女厕',
    en: 'Female Toilet'
  }
};

const nextFuncAfterLoad = (
  results,
  originalColumn,
  setColumns,
  cardGenerator,
  useKeyCTOSeparate,
  firstLoad,
  setFirstLoad,
  setData,
  lang = 'en'
) => {
  let floorList = results.data.floorList;
  // console.log(floorList);
  // let floorOrder = results.data.floorOrder;
  let columnLists = results.data.columnLists;
  //setSensor_contents(results.data.sensor_contents);
  let todoColumns = columnLists;
  Promise.all(
    // floorOrder.map((key) => {
    floorList.map((value, index) => {
      return new Promise((resolve) => {
        // if (floorList.hasOwnProperty(key)) {
        let result = floorGenerator(
          index,
          value.floor,
          value,
          todoColumns,
          cardGenerator,
          useKeyCTOSeparate
        );

        resolve([result, result.deviceListShow]);
        // }
      });
    })
    // })
  ).then((display) => {
    if (firstLoad) {
      let display_deviceListShow =
        display.map((x) => x[1]).filter((x) => x == true).length > 0;
      let columns = [...originalColumn];
      let remainColumn = columns.splice(1, 1);

      if (useKeyCTOSeparate)
        columns.push(
          ...columnLists.map((col) => {
            return {
              title: Object.keys(columnTranslateNames).find((x) => x == col)
                ? columnTranslateNames[col][lang]
                : col,
              field: 'devices' + col,
              key: 'devices' + col,
              dataIndex: 'devices' + col,
              headerStyle: {
                fontWeight: 'Bold'
              }
              // width: '60%'
            };
          })
        );
      if (display_deviceListShow) columns.push(remainColumn[0]);
      setColumns(columns);

      setFirstLoad(false);
    }
    let remainResultDisplay = display
      .map((x) => x[0])
      .filter((x) => x.rawDevices.length > 0);
    setData(remainResultDisplay);
  });
};

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // 儲存新回撥
  useEffect(() => {
    savedCallback.current = callback;
  });

  // 建立 interval
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

let commonfunctions = {
  getCompany,
  getPagePermission,
  findCommon,
  floorGenerator,
  nextFuncAfterLoad,
  getCompanyFloorType
};

export {
  getCompany,
  getCompanyFloor,
  getCompanyFloorType,
  getPagePermission,
  findCommon,
  removeGenderToilet,
  removeGenderToiletBJAPM,
  floorGenerator,
  nextFuncAfterLoad,
  useInterval
};

export default commonfunctions;
