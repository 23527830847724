import React, { lazy, Suspense, useState, useEffect } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ClimbingBoxLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import {
  LeftSidebar,
  MinimalLayout,
  PresentationLayout
} from './layout-blueprints';
//////////////////try tabs
import LT_logo from './assets/images/myImage/LT_fullname.png';
import { Layout, Menu } from 'antd';
import './for_layout_header.css';
import NotifySuccess from './utils/toastSuccess';
import CheckPermission from './services/auth.permission';
import commonVariables from './config/commonSetting';

import PageManager from './components/PageManager';

const { Header } = Layout;
const languageMap = commonVariables.languageMap;
/////////////////////////////////////////////
const PageTesting = lazy(() => import('./myPages/MapOverlay/testing'));

const PageLoginBasic = lazy(() => import('./myPages/Login/login_page')); //remember this

const PageHome = lazy(() => import('./myPages/Home/home_page2'));

const PageWhatsapp = lazy(() =>
  import('./myPages/Setup/Whatsapp_page/Whatsapp_app')
);
//request
// const PageRequestOverview = lazy(() =>
//   import('./myPages/Request/Overview/Request_app')
// );
// const PageRequestForm = lazy(() =>
//   import('./myPages/Request/RequestForm/Request_app')
// );

const PageRequestOverview = lazy(() =>
  import('./myPages/WorkForm/workform_review/workform_form_man_App')
);
const PageRequestForm = lazy(() =>
  import('./myPages/WorkForm/workform_form/workform_App')
);

// const PageWorkFormStaff = lazy(() =>
//   import('./myPages/WorkForm/workform_staff/workform_App')
// );
//devices
const PageSmartToilet = lazy(() =>
  import('./myPages/DevicePage/EntranceDashboard/EntranceDashboard_app')
);

const PageWaterLeakage = lazy(() =>
  import('./myPages/DevicePage/WaterLeakage/WaterLeakage_app')
);
const PageIAQ = lazy(() => import('./myPages/DevicePage/IAQ/IAQ_app'));
const PageSmoke = lazy(() => import('./myPages/DevicePage/Smoke/smoke_app'));
const PageCubicle = lazy(() =>
  import('./myPages/DevicePage/Cubicle/Cubicle_app')
);
const PageSoap = lazy(() => import('./myPages/DevicePage/Soap/Soap_app'));
const PageEmergencyDoor = lazy(() =>
  import('./myPages/DevicePage/EmergencyDoor/Cubicle_app')
);
const PageMotionSensor = lazy(() =>
  import('./myPages/DevicePage/MotionSensor/Cubicle_app')
);
const PageIndicator = lazy(() =>
  import('./myPages/DevicePage/Indicator/Cubicle_app')
);
const PageGensetMonitor = lazy(() =>
  import('./myPages/DevicePage/GensetMonitor/Cubicle_app')
);
const PageHandPaper = lazy(() =>
  import('./myPages/DevicePage/HandPaper/handpaper_app')
);

const PageQueue = lazy(() => import('./myPages/DevicePage/Queue/Queue_app'));
const PageGPIO = lazy(() => import('./myPages/DevicePage/GPIO/GPIO_app'));

const PagePeopleCount = lazy(() =>
  import('./myPages/DevicePage/PeopleCount/PeopleCount_app')
);
const PageDoor = lazy(() => import('./myPages/DevicePage/Door/Door_app'));

const PageUrinal = lazy(() => import('./myPages/DevicePage/Urinal/Urinal_app'));
const PageUVC = lazy(() => import('./myPages/DevicePage/UVC/UVC_app'));

const PageStorage = lazy(() =>
  import('./myPages/DevicePage/Storage/Storage_app')
);
const PageWaterFlow = lazy(() =>
  import('./myPages/DevicePage/WaterFlow/WaterFlow_app')
);
const PageWaterLevel = lazy(() =>
  import('./myPages/DevicePage/WaterLevel/WaterLevel_app')
);
const PageEValve = lazy(() => import('./myPages/DevicePage/eValve/eValve_app'));
const PageTrashBin = lazy(() =>
  import('./myPages/DevicePage/TrashBin/TrashBin_app')
);

const PageCigarette = lazy(() =>
  import('./myPages/DevicePage/Cigarette/Cigarette_app')
);
const PageFireAlarm = lazy(() =>
  import('./myPages/DevicePage/FireAlarm/FireAlarm_app')
);
const PageAQNO2 = lazy(() =>
  import('./myPages/DevicePage/TrashBin/TrashBin_app')
);
const PageRollPaper = lazy(() =>
  import('./myPages/DevicePage/RollPaper/RollPaper_app')
);
const PageSink = lazy(() =>
  import('./myPages/DevicePage/SinkOccupancy/Sink_app')
);
const PageRepeater = lazy(() =>
  import('./myPages/DevicePage/Repeater/Repeater_app')
);
const PageUnauth = lazy(() => import('./myPages/Unauth_Page/unauth_app'));
//activityLog
const PageOfflineLiteLog = lazy(() =>
  import('./myPages/ActivityLog/ActivityLog_offline_lite/Log_app')
);
const PageWaterLog = lazy(() =>
  import('./myPages/ActivityLog/ActivityLog_water/Log_app')
);
const PageCubicleLog = lazy(() =>
  import('./myPages/ActivityLog/ActivityLog_Cubicle/Log_app')
);

const PageEmergencyDoorLog = lazy(() =>
  import('./myPages/ActivityLog/ActivityLog_EmergencyDoor/Log_app')
);
const PageMotionSensorLog = lazy(() =>
  import('./myPages/ActivityLog/ActivityLog_MotionSensor/Log_app')
);
const PageIndicatorLog = lazy(() =>
  import('./myPages/ActivityLog/ActivityLog_Indicator/Log_app')
);

const PageCigaretteLog = lazy(() =>
  import('./myPages/ActivityLog/ActivityLog_Cigarette/Log_app')
);

const PageSoapLog = lazy(() =>
  import('./myPages/ActivityLog/ActivityLog_soap/Log_app')
);
const PageHandLog = lazy(() =>
  import('./myPages/ActivityLog/ActivityLog_HandPaper/Log_app')
);

const PageRollPaperLog = lazy(() =>
  import('./myPages/ActivityLog/ActivityLog_RollPaper/Log_app')
);
const PageQueueLog = lazy(() =>
  import('./myPages/ActivityLog/ActivityLog_Queue/Log_app')
);

const PageSinkLog = lazy(() =>
  import('./myPages/ActivityLog/ActivityLog_Sink/Log_app')
);

const PageValveLog = lazy(() =>
  import('./myPages/ActivityLog/ActivityLog_eValve/Log_app')
);
const PageBinLog = lazy(() =>
  import('./myPages/ActivityLog/ActivityLog_trashBin/Log_app')
);
const PageDoorLog = lazy(() =>
  import('./myPages/ActivityLog/ActivityLog_Door/Log_app')
);
const PageUVCLog = lazy(() =>
  import('./myPages/ActivityLog/ActivityLog_uvc/Log_app')
);

//analysis
const PageAnalysisWaterLeakage = lazy(() =>
  import('./myPages/Analysis/WaterLeakage/WaterLeakage_app')
);
const PageAnalysisIAQ = lazy(() => import('./myPages/Analysis/IAQ/IAQ_app'));
const PageAnalysisSmoke = lazy(() =>
  import('./myPages/Analysis/Smoke/smoke_app')
);
const PageAnalysisCubicle = lazy(() =>
  import('./myPages/Analysis/Cubicle/Cubicle_app')
);
const PageAnalysisSoap = lazy(() => import('./myPages/Analysis/Soap/Soap_app'));
const PageAnalysisHandPaper = lazy(() =>
  import('./myPages/Analysis/HandPaper/handpaper_app')
);

const PageAnalysisQueue = lazy(() =>
  import('./myPages/Analysis/Queue/Queue_app')
);
const PageAnalysisPeopleCount = lazy(() =>
  import('./myPages/Analysis/PeopleCount/PeopleCount_app')
);
const PageAnalysisDoor = lazy(() => import('./myPages/Analysis/Door/Door_app'));

const PageAnalysisUrinal = lazy(() =>
  import('./myPages/Analysis/Urinal/Urinal_app')
);
const PageAnalysisWaterFlow = lazy(() =>
  import('./myPages/Analysis/WaterFlow/WaterFlow_app')
);
const PageAnalysisWaterLevel = lazy(() =>
  import('./myPages/Analysis/WaterLevel/WaterLevel_app')
);
const PageAnalysisEValve = lazy(() =>
  import('./myPages/Analysis/eValve/eValve_app')
);
const PageAnalysisTrashBin = lazy(() =>
  import('./myPages/Analysis/TrashBin/TrashBin_app')
);

const PageAnalysisCigarette = lazy(() =>
  import('./myPages/Analysis/Cigarette/Cigarette_app')
);
const PageAnalysisFireAlarm = lazy(() =>
  import('./myPages/Analysis/FireAlarm/FireAlarm_app')
);
const PageAnalysisAQNO2 = lazy(() =>
  import('./myPages/Analysis/TrashBin/TrashBin_app')
);
const PageAnalysisRollPaper = lazy(() =>
  import('./myPages/Analysis/RollPaper/RollPaper_app')
);
const PageAnalysisSink = lazy(() =>
  import('./myPages/Analysis/SinkOccupancy/Sink_app')
);
const PageAnalysisRepeater = lazy(() =>
  import('./myPages/Analysis/Repeater/Repeater_app')
);
/////////////////////////////
const PageLiTeDetail = lazy(() =>
  import('./myPages/Details/LiTeDetail/tv_app')
);
const PageEvalveConfig = lazy(() =>
  import('./myPages/Details/EValveConfig/tv_app')
);

const PageReport = lazy(() => import('./myPages/Details/LiTeDetail/tv_app')); //import('./myPages/test/report'));
const PageDeveloping = lazy(() => import('./myPages/developing/develop_page'));
const PageNotSupport = lazy(() =>
  import('./myPages/not_support/noSupport_page')
);
const PageEntrance = lazy(() =>
  import('./myPages/entrance_overview/entrance_app')
);
const PageEntrance2 = lazy(() => import('./myPages/Entrance/entrance_app'));
const PageEntranceCyberport = lazy(() =>
  import('./myPages/Tplus_Entrance/entranceAnnex_app')
);
const PageEntranceCyberportPTI = lazy(() =>
  import('./myPages/Tplus_Entrance/entrancePTI_app')
);
const PageEntranceCyberportPTITest = lazy(() =>
  import('./myPages/Tplus_Entrance/Location/PTI/entrancePTI_app_test')
);
const PageEntranceAccelGroup = lazy(() =>
  import('./myPages/Tplus_Entrance/Location/AccelGroup/entranceAccelGroup_app')
);
const PageEntranceBJAPM = lazy(() =>
  import('./myPages/Tplus_Entrance/entranceBJAPM_app')
);

const PageTeleport = lazy(() => import('./myPages/Teleport/entrance_app'));
const PageFloorOrder = lazy(() =>
  import('./myPages/Setup/FloorOrder/floorOrder_app')
);
const PageMapView = lazy(() => import('./myPages/Map/map'));
const PageFloorPlan = lazy(() => import('./myPages/FloorPlan/floorPlan_app'));
const PagePiDetail = lazy(() => import('./myPages/Setup/Pi_detail/Pi_app'));
const PageLiteHistory = lazy(() => import('./myPages/ViewLiteHistory/tv_app'));
const PageLiteHistoryNew = lazy(() =>
  import('./myPages/ViewLiteHistoryNew/tv_app')
);
const PageAddLite = lazy(() => import('./myPages/Setup/AddLite/addLite_app'));
const PageUser = lazy(() => import('./myPages/Setup/addUser/User_app'));
const PageAddThermal = lazy(() => import('./myPages/Setup/addThermal/Add_app'));
const PageAddQueueSum = lazy(() =>
  import('./myPages/Setup/addThermal/Add_app')
);
const PageAddGPIO = lazy(() => import('./myPages/Setup/addGPIO/AddGPIO_app'));
const PageCompany = lazy(() =>
  import('./myPages/Setup/addCompany/Company_app')
);
const PageProperty = lazy(() =>
  import('./myPages/Setup/addProperty/Property_app')
);

const PageRTSPlayer = lazy(() => import('./myPages/RTSP_page/rtsp_player_app'));
const PagePlayground = lazy(() =>
  import('./myPages/ReportGraphPlayground/playground')
);
const PageProfileEdit = lazy(() =>
  import('./myPages/Setup/Profile/profileEdit_app')
);

const PageBookDashboard = lazy(() =>
  import('./myPages/LibSys/BookDashboard/App.js')
);
const PageBorrowBook = lazy(() => import('./myPages/LibSys/BorrowBook/App.js'));
const PageBorrowDashboard = lazy(() =>
  import('./myPages/LibSys/BorrowDashboard/App.js')
);
const PageReturnBook = lazy(() => import('./myPages/LibSys/ReturnBook/App.js'));

const Routes = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  function teleport() {
    return (
      <Switch>
        <Redirect to="/PageTeleport" />
      </Switch>
    );
  }

  function not_support() {
    return (
      <Switch>
        <Redirect to="/PageNotSupport" />
      </Switch>
    );
  }

  const SuspenseLoading = () => {
    return (
      <>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            {t('loading')}
          </div>
        </div>
      </>
    );
  };

  //for translation
  const selectedLang = localStorage.getItem('i18nextLng') || 'tc';
  const { t } = useTranslation();
  const [menuAnchor, setMenuAnchor] = useState(null);
  useEffect(() => {
    document.body.dir = languageMap[selectedLang].dir;
  }, [menuAnchor, selectedLang]);

  let [allLoginedPages, setAllLoginedPages] = useState([]);

  let dynamicLoadFromPageManager = () => {
    let allRaw = PageManager.getAllPageTitle();
    allRaw = allRaw.map((pagename) => '/' + pagename);
    // console.log(allRaw);
    setAllLoginedPages(allRaw);
  };
  useEffect(() => {
    dynamicLoadFromPageManager();
  }, []);

  return (
    <AnimatePresence>
      <Suspense fallback={<SuspenseLoading />}>
        <Switch>
          <Redirect exact from="/" to="/PageLogin" />
          <Route path={['/PageLogin']}>
            <PresentationLayout>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/PageLogin" component={PageLoginBasic} />
                  {/* <Route path="/PageLogin" component={PageLoginCyberport} />  this is cyberport smart toilet version login page*/}
                </motion.div>
              </Switch>
            </PresentationLayout>
          </Route>

          {/* <StickyContainer>
              <Tabs defaultActiveKey="1" type="card" size='large' renderTabBar={renderTabBar} >
              <TabPane tab="Card Tab 1" key="1"> */}

          <Route path={allLoginedPages}>
            <CheckPermission />
            <LeftSidebar>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/PageTesting" component={PageTesting} />
                  <Route path="/PageHome" component={PageHome} />
                  <Route
                    path="/PageRequestOverview"
                    component={PageRequestOverview}
                  />
                  <Route path="/PageRequestForm" component={PageRequestForm} />
                  <Route path="/PageSmartToilet" component={PageSmartToilet} />
                  <Route
                    path="/PageWaterLeakage"
                    component={PageWaterLeakage}
                  />
                  <Route path="/PageEValve" component={PageEValve} />
                  <Route path="/PageIAQ" component={PageIAQ} />
                  <Route path="/PageUVC" component={PageUVC} />
                  <Route path="/PageUVCLog" component={PageUVCLog} />
                  <Route path="/PageCubicle" component={PageCubicle} />
                  <Route
                    path="/PageEmergencyDoor"
                    component={PageEmergencyDoor}
                  />
                  <Route
                    path="/PageMotionSensor"
                    component={PageMotionSensor}
                  />
                  <Route path="/PageIndicator" component={PageIndicator} />
                  <Route path="/PageGensetMonitor" component={PageGensetMonitor} />
                  <Route path="/PageSmoke" component={PageSmoke} />
                  <Route path="/PageSoap" component={PageSoap} />
                  <Route path="/PageHandPaper" component={PageHandPaper} />
                  <Route path="/PageMapView" component={PageMapView} />
                  <Route path="/PageQueue" component={PageQueue} />
                  <Route path="/PageGPIO" component={PageGPIO} />
                  <Route path="/PagePeopleCount" component={PagePeopleCount} />
                  <Route path="/PageDoor" component={PageDoor} />
                  <Route path="/PageUrinal" component={PageUrinal} />
                  <Route path="/PageStorage" component={PageStorage} />
                  <Route path="/PageWaterFlow" component={PageWaterFlow} />
                  <Route path="/PageWaterLevel" component={PageWaterLevel} />
                  <Route path="/PageTrashBin" component={PageTrashBin} />
                  <Route path="/PageCigarette" component={PageCigarette} />
                  <Route path="/PageFireAlarm" component={PageFireAlarm} />
                  <Route path="/PageAQNO2" component={PageAQNO2} />
                  <Route path="/PageRollPaper" component={PageRollPaper} />
                  <Route path="/PageSink" component={PageSink} />
                  <Route path="/PageRepeater" component={PageRepeater} />
                  <Route path="/PageUnauth" component={PageUnauth} />
                  <Route path="/PageWhatsapp" component={PageWhatsapp} />
                  <Route
                    path="/PageOfflineLiteLog"
                    component={PageOfflineLiteLog}
                  />
                  <Route path="/PageWaterLog" component={PageWaterLog} />
                  <Route path="/PageDoorLog" component={PageDoorLog} />
                  <Route path="/PageSoapLog" component={PageSoapLog} />
                  <Route path="/PageCubicleLog" component={PageCubicleLog} />
                  <Route
                    path="/PageEmergencyDoorLog"
                    component={PageEmergencyDoorLog}
                  />
                  <Route
                    path="/PageMotionSensorLog"
                    component={PageMotionSensorLog}
                  />
                  <Route
                    path="/PageIndicatorLog"
                    component={PageIndicatorLog}
                  />
                  <Route
                    path="/PageCigaretteLog"
                    component={PageCigaretteLog}
                  />
                  <Route path="/PageHandLog" component={PageHandLog} />
                  <Route
                    path="/PageRollPaperLog"
                    component={PageRollPaperLog}
                  />
                  <Route path="/PageQueueLog" component={PageQueueLog} />
                  <Route path="/PageSinkLog" component={PageSinkLog} />
                  <Route path="/PageValveLog" component={PageValveLog} />
                  <Route path="/PageBinLog" component={PageBinLog} />
                  <Route
                    path="/PageAnalysisWaterLeakage"
                    component={PageAnalysisWaterLeakage}
                  />
                  <Route
                    path="/PageAnalysisEValve"
                    component={PageAnalysisEValve}
                  />
                  <Route path="/PageAnalysisIAQ" component={PageAnalysisIAQ} />
                  <Route
                    path="/PageAnalysisCubicle"
                    component={PageAnalysisCubicle}
                  />
                  <Route
                    path="/PageAnalysisSmoke"
                    component={PageAnalysisSmoke}
                  />
                  <Route
                    path="/PageAnalysisSoap"
                    component={PageAnalysisSoap}
                  />
                  <Route
                    path="/PageAnalysisHandPaper"
                    component={PageAnalysisHandPaper}
                  />
                  <Route
                    path="/PageAnalysisQueue"
                    component={PageAnalysisQueue}
                  />
                  <Route
                    path="/PageAnalysisPeopleCount"
                    component={PageAnalysisPeopleCount}
                  />
                  <Route
                    path="/PageAnalysisDoor"
                    component={PageAnalysisDoor}
                  />
                  <Route
                    path="/PageAnalysisUrinal"
                    component={PageAnalysisUrinal}
                  />
                  <Route
                    path="/PageAnalysisWaterFlow"
                    component={PageAnalysisWaterFlow}
                  />
                  <Route
                    path="/PageAnalysisWaterLevel"
                    component={PageAnalysisWaterLevel}
                  />
                  <Route
                    path="/PageAnalysisTrashBin"
                    component={PageAnalysisTrashBin}
                  />
                  <Route
                    path="/PageAnalysisCigarette"
                    component={PageAnalysisCigarette}
                  />
                  <Route
                    path="/PageAnalysisFireAlarm"
                    component={PageAnalysisFireAlarm}
                  />
                  <Route
                    path="/PageAnalysisAQNO2"
                    component={PageAnalysisAQNO2}
                  />
                  <Route
                    path="/PageAnalysisRollPaper"
                    component={PageAnalysisRollPaper}
                  />
                  <Route
                    path="/PageAnalysisSink"
                    component={PageAnalysisSink}
                  />
                  <Route
                    path="/PageAnalysisRepeater"
                    component={PageAnalysisRepeater}
                  />
                  <Route path="/PageLiTeDetail" component={PageLiTeDetail} />
                  <Route
                    path="/PageEvalveConfig"
                    component={PageEvalveConfig}
                  />
                  <Route path="/PageReport" component={PageReport} />
                  <Route path="/PageDeveloping" component={PageDeveloping} />
                  {/* <Route path="/PageNotSupport" component={PageNotSupport} /> */}
                  <Route path="/PageEntrance" component={PageEntrance} />
                  <Route path="/PageTeleport" component={PageTeleport} />
                  <Route path="/PageFloorOrder" component={PageFloorOrder} />
                  <Route path="/PageFloorPlan" component={PageFloorPlan} />
                  <Route path="/PagePiDetail" component={PagePiDetail} />
                  <Route path="/PageLiteHistory" component={PageLiteHistory} />
                  <Route
                    path="/PageLiteHistoryNew"
                    component={PageLiteHistoryNew}
                  />
                  <Route path="/PageAddLite" component={PageAddLite} />
                  <Route path="/PageUser" component={PageUser} />
                  <Route path="/PageAddThermal" component={PageAddThermal} />
                  <Route path="/PageAddGPIO" component={PageAddGPIO} />
                  <Route path="/PageAddQueueSum" component={PageAddQueueSum} />
                  <Route path="/PageCompany" component={PageCompany} />
                  <Route path="/PageProperty" component={PageProperty} />
                  <Route path="/PageRTSPlayer" component={PageRTSPlayer} />
                  <Route path="/PageProfileEdit" component={PageProfileEdit} />
                  <Route path="/PagePlayground" component={PagePlayground} />
                  <Route
                    path="/PageBookDashboard"
                    component={PageBookDashboard}
                  />
                  <Route path="/PageBorrowBook" component={PageBorrowBook} />
                  <Route
                    path="/PageBorrowDashboard"
                    component={PageBorrowDashboard}
                  />
                  <Route path="/PageReturnBook" component={PageReturnBook} />
                </motion.div>
              </Switch>
            </LeftSidebar>
          </Route>

          {/* </TabPane>
            <TabPane tab="Card Tab 2" key="2"></TabPane>
            <TabPane tab="Card Tab 3" key="3"></TabPane>
            </Tabs>
            </StickyContainer> */}
          <Route path={['/PageLogin']}>
            <MinimalLayout>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/PageLogin" component={PageLoginBasic} />
                </motion.div>
              </Switch>
            </MinimalLayout>
          </Route>

          <Route
            path={[
              '/PageEntrance',
              '/PageEntrance2',
              '/PageEntranceCyberportAnnex',
              '/PageEntranceCyberportPTI',
              '/PageEntranceCyberportPTITest',
              '/PageEntranceAccelGroup',
              '/PageEntranceBJAPM'
            ]}>
            <MinimalLayout>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/PageEntrance" component={PageEntrance} />
                  <Route path="/PageEntrance2" component={PageEntrance2} />
                  <Route
                    path="/PageEntranceCyberportAnnex"
                    component={PageEntranceCyberport}
                  />
                  <Route
                    path="/PageEntranceCyberportPTI"
                    component={PageEntranceCyberportPTI}
                  />
                  <Route
                    path="/PageEntranceCyberportPTITest"
                    component={PageEntranceCyberportPTITest}
                  />
                  <Route
                    path="/PageEntranceAccelGroup"
                    component={PageEntranceAccelGroup}
                  />
                  <Route
                    path="/PageEntranceBJAPM"
                    component={PageEntranceBJAPM}
                  />
                </motion.div>
              </Switch>
            </MinimalLayout>
          </Route>

          {
            //WorkForm Without Login
          }
          <MinimalLayout>
            <Switch>
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}>
                <Route
                  path="/PageRequestFormWithoutPW"
                  component={PageRequestForm}
                />
              </motion.div>
            </Switch>
          </MinimalLayout>
          {
            //WorkForm Without Login end
          }

          {/* 1 route 1 system */}
          <Route path={['/PageTeleport']}>
            <Layout className="layout">
              <Header
                style={{
                  backgroundColor: 'black',
                  position: 'fixed',
                  zIndex: 100000,
                  width: '100%'
                }}>
                <a href="/PageHome#/AVANIoT/PageHome">
                  <div className="logo">
                    <img
                      src={LT_logo}
                      style={{
                        height: '60px',
                        float: 'left',
                        paddingRight: '120px'
                      }}
                      // style={{ float: 'right', margin: '16px 0 16px 24px'}}
                    />
                  </div>
                </a>
                <Menu
                  theme="dark"
                  mode="horizontal"
                  defaultSelectedKeys={['3']}
                  style={{ backgroundColor: 'black' }}>
                  <Menu.Item key="1">IoT Sensor</Menu.Item>

                  <Menu.Item key="2">
                    <a href="#/AVANIoT/PageHome">Smart Toilet</a>
                  </Menu.Item>

                  <Menu.Item key="3">
                    <a href="#/AVANIoT/PageTeleport">IoT Lighting </a>
                  </Menu.Item>

                  <Menu.Item
                    key="4"
                    onClick={() => {
                      teleport();
                      console.log('clicked');
                    }}>
                    {' '}
                    Security System
                  </Menu.Item>

                  <Menu.Item key="5">Carpark System</Menu.Item>
                </Menu>
              </Header>
            </Layout>

            <MinimalLayout>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/PageTeleport" component={PageTeleport} />
                </motion.div>
              </Switch>
            </MinimalLayout>
          </Route>

          {/* 1 route 1 system */}
          <Route path={['/PageNotSupport']}>
            <MinimalLayout>
              <Switch>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/PageNotSupport" component={PageNotSupport} />
                </motion.div>
              </Switch>
            </MinimalLayout>
          </Route>
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

export default withRouter(Routes);
