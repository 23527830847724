import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Badge,
  ListGroup,
  ListGroupItem,
  UncontrolledTooltip,
  Nav,
  NavItem,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';
import { NavLink as NavLinkStrap } from 'reactstrap';
import avatar7 from '../../assets/images/avatars/avatar7.jpg';

const HeaderUserbox = () => {
  return (
    <>
      <UncontrolledDropdown className="position-relative ml-2">
        
      </UncontrolledDropdown>
    </>
  );
};

export default HeaderUserbox;
