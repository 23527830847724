class CustomizedProp {
  setInformation(response) {
    if (response) {
      this.setLoginHome(response.data.customized.login_home);
    }
  }
  // getInformationFromBackend(accessToken) {
  //   if (!accessToken) {
  //     accessToken = AuthService.getCurrentUser().accessToken;
  //     if (!accessToken) {
  //       console.log('Not logined, halt!');
  //       return;
  //     }
  //   }
  //   axios
  //     .get(hostname + '/user/getCustomized', {
  //       params: { accessToken }
  //     })
  //     .then((response) => {
  //       this.setInformation(response);
  //     }).catch((err)=>{
  //       console.log("Error: Cannot get the latest information!", err);
  //     });
  // }

  setLoginHome(login_home) {
    localStorage.setItem('login_home', JSON.stringify(login_home));
  }

  getLoginHome() {
    if (localStorage.getItem('login_home'))
      try {
        return JSON.parse(localStorage.getItem('login_home'));
      } catch {
        return 1;
      }
    else return null;
  }
}

export default new CustomizedProp();
