let development =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

let hostname = development
  ? 'http://localhost:2047'
  : window.location.protocol + '//' + window.location.host; //+window.location.host;
let wshostname = development
  ? 'ws://localhost:2047'
  : 'ws://' + window.location.host;
let ws2hostname = development
  ? 'ws://localhost:2048'
  : 'ws://' + window.location.hostname + ':2048';
let webpagehostname = development
  ? 'http://localhost:3000'
  : window.location.protocol + '//' + window.location.host;

const languageMap = {
  en: { label: 'English', dir: 'ltr', active: true }, //ltr: left to right
  tc: { label: '繁體中文', dir: 'ltr', active: false }, //rtl: right to left,  有d人向左/向右睇起
  sc: { label: '简体中文', dir: 'ltr', active: false },
  th: { label: 'Thai', dir: 'ltr', active: false },
};

const ep_types = {
  R0: 'N/A', //Dec:0 	Hex:0x00	Bin: 0b000 000 <-- X
  EP_PL_LIGHTING: 'Lighting (Powerline)', //Dec:1 	Hex:0x01	Bin: 0b000 001 <-- X
  EP_LIGHTING: 'Lighting (LoRa)', //Dec:2 	Hex:0x02	Bin: 0b000 010 <-- X
  EP_WATER_LEAK_BAT: 'Water Leakage', //Dec:3 	Hex:0x03	Bin: 0b000 011 <-- On/Off
  EP_DOOR_BAT: 'Cubicle (Battery)', //Dec:4 	Hex:0x04	Bin: 0b000 100 <-- On/Off
  EP_IAQ_DC: 'IAQ', //Dec:5 	Hex:0x05	Bin: 0b000 101 <-- IAQ
  EP_QUEUE_TOF: 'Queue ToF', //Dec:6 	Hex:0x06	Bin: 0b000 110 <-- ToF x N
  EP_URINAL: 'Urinal ToF', //Dec:7 	Hex:0x07	Bin: 0b000 111 <-- On/Off
  EP_WATERFLOW: 'Waterflow', //Dec:8 	Hex:0x08	Bin: 0b001 000 <-- Pulse?
  EP_SMOKE: 'Smoke (Battery)', //Dec:9 	Hex:0x09	Bin: 0b001 001 <-- On/Off
  EP_SOAP: 'Soap Dispenser (Battery)', //Dec:10 	Hex:0x0A	Bin: 0b001 010 <-- On/Off
  EP_DOOR_LED: 'Cubicle with LED', //Dec:11 	Hex:0x0B	Bin: 0b001 011 <-- On/Off with LED tube?
  EP_AIRSPOT: 'airSPOT', //Dec:12 	Hex:0x0C	Bin: 0b001 100 <-- IAQ
  EP_TRASH_BIN: 'Bin (Battery)', //Dec:13 	Hex:0x0D	Bin: 0b001 101 <--	ToF
  EP_HAND_PAPER: 'Hand Paper (Battery)', //Dec:14 	Hex:0x0E	Bin: 0b001 110 <--	ToF
  EP_TOILET_PAPER: 'Roll Paper (Battery)', //Dec:15 	Hex:0x0F	Bin: 0b001 111 <--	ToF
  EP_REPEATER: 'Repeater', //Dec:16 	Hex:0x10	Bin: 0b010 000 <-- Testing Tools
  EP_SENDER: 'Sender', //Dec:17 	Hex:0x11	Bin: 0b010 001 <-- Testing Tools
  EP_RECEIVER: 'Receiver', //Dec:18 	Hex:0x12	Bin: 0b010 010 <-- Testing Tools
  EP_PPL_COUNT: 'People Counter (IR)', //Dec:19 	Hex:0x13	Bin: 0b010 011 <-- PPL by 1 line IR
  EP_RFID_READER: 'RFID Reader', //Dec:20 	Hex:0x14	Bin: 0b010 100
  EP_AIRSPOT_NO2: 'airSPOT NO2', //Dec:21 	Hex:0x15	Bin: 0b010 101 <-- AlphaSense NO2 sensor
  EP_E_VALVE: 'E-Valve', //Dec:22 	Hex:0x16	Bin: 0b010 110
  EP_ACCESS_DOOR: 'Accessible Door', //Dec:23 	Hex:0x17	Bin: 0b010 111
  EP_SMOKE_FIRE_ALARM: 'Fire Alarm (Smoke)', //Dec:24 	Hex:0x18	Bin: 0b011 000
  EP_ACT_UV_LIGHT: 'UVC Lighting', //Dec:25 	Hex:0x19	Bin: 0b011 001
  EP_SONIC_LIQUIDLEVEL: 'Liquid Level (Sonic)', //Dec:26 	Hex:0x1A	Bin: 0b011 010 <-- Sonic sensor: Liquid Level
  EP_SONIC_DISTANCE: 'Distance (Sonic)', //Dec:27 	Hex:0x1B	Bin: 0b011 011 <-- Sonic sensor: Distance
  EP_THERMAL: 'Thermal', //Dec:28 	Hex:0x1C	Bin: 0b011 100 <-- 8x8 Thermal

  EP_SMOKE_AC: 'Smoke (AC)', //Dec:29 	Hex:0x1D	Bin: 0b011 101 <-- On/Off
  EP_CUBICLE_OCCU_TOF_LED_AC: 'Cubicle ToF (AC)', //Dec:30 	Hex:0x1E	Bin: 0b011 110 <-- ToF x 1
  EP_DOOR_AC: 'Door (AC)', //Dec:31 	Hex:0x1F	Bin: 0b011 111 <-- On/Off
  EP_SOAP_AC: 'Soap (AC)', //Dec:32 	Hex:0x20	Bin: 0b100 000 <-- On/Off
  EP_HAND_PAPER_TOF_AC: 'Hand Paper ToF (AC)', //Dec:33 	Hex:0x21	Bin: 0b100 001 <-- ToF x 1
  EP_ROLL_PAPER_TOF_AC: 'Roll Paper ToF (AC)', //Dec:34 	Hex:0x22	Bin: 0b100 010 <-- ToF x 1
  EP_QUEUE_OCCU_TOF_AC: 'Queue ToF (AC)', //Dec:35 	Hex:0x23	Bin: 0b100 011 <-- ToF x 1
  EP_SINK_OCCU_TOF_AC: 'Sink ToF (AC)', //Dec:36 	Hex:0x24	Bin: 0b100 100 <-- ToF x 1
  EP_ACT_FLUSH: 'Actuator Flush', //Dec:37 	Hex:0x25	Bin: 0b100 101 <-- Actuator, flush
  EP_WEIGHT: 'Weight Sensor', //Dec:38 	Hex:0x26	Bin: 0b100 110 <-- Weight sensor
  EP_IO_BOARD: 'IO Board', //Dec:39 	Hex:0x27	Bin: 0b100 111 <-- IO board
  EP_FLUSH_OCCU_TOF_AC: 'Toilet Flush with Occupancy ToF (AC)', //Dec:40	Hex:0x28	Bin: 0b101 000 <-- flush actuator
  EP_FLUSH_SENSE: 'Flush Sensor', //Dec:41	Hex:0x29	Bin: 0b101 001 <-- flush sensor

  EP_WATER_LEAK_AC: 'Water Leakage (AC)', //Dec:42	Hex:0x2A	Bin: 0b101 010 <-- On/Off
  EP_DMR_TRANSLATOR: 'DMR Translator', //Dec:43	Hex:0x2A	Bin: 0b101 011 <-- On/Off
  EP_BASE_STATION_MONITOR: 'BSM Monitor' //Dec:44	Hex:0x2A	Bin: 0b101 100 <-- On/Off
};

let commonVariables = {
  development,
  hostname,
  wshostname,
  ws2hostname,
  webpagehostname,
  languageMap,
  ep_types
};

export default commonVariables;
