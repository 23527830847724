import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { toast, Slide, Flip, Zoom } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function Notify(props) {
  const { t } = useTranslation();

  return( toast.success(t('popup_toast_success'), {
    containerId: 'B'
  }));
}
